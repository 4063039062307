import React from "react";
import {
  SEO,
  PageLayout,
  Map,
  ContactForm,
  Location,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          className={"contact-page"}
          hero={
            <Map
              address={{
                street: fishermanBusiness.primaryLocation.street,
                city: fishermanBusiness.primaryLocation.city,
                state: fishermanBusiness.primaryLocation.state,
                zip: fishermanBusiness.primaryLocation.zipCode,
              }}
            />
          }
        >
          <Grid stackable={true}>
            <Grid.Column width={4} />
            <Grid.Column width={8}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Contact Us"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as={"h5"}>Contact Info</Header>
              <Location
                businessName={fishermanBusiness.name}
                phone={fishermanBusiness.primaryLocation.phoneNumber}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
              />
              <HoursOfOperation
                hours={fishermanBusiness.primaryLocation.hours}
                displayOption={"grouped"}
              />
            </Grid.Column>
          </Grid>
          <Image
            size={"large"}
            centered={true}
            src={
              "https://fisherman.gumlet.io/public/securitysolutionsnetworkinc/renditionDownload.png"
            }
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        street
        city
        state
        zipCode
        _id
        phoneNumber
        hours {
          day
          open
          close
          label
        }
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
    }
  }
`;
